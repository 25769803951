<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })"  id="fzcolor">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:CHANNEL'">
      <div>
        <div class="desc">
          <h5 class="jsxx">电子账簿信息</h5>
        </div>
        <div class="tab1" style="margin-bottom: 63px;">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>开户申请单号</p>
              </td>
              <td>
                <p>商户主体ID</p>
              </td>
              <td>
                <p>开户状态</p>
              </td>
              <td>
                <p>收款账号认证状态</p>
              </td>
              <td>
                <p>错误描述</p>
              </td>
              <td>
                <p>账户信息</p>
              </td>
              <td>
                <p>操作</p>
              </td>
            </tr>
            <tr v-for="(v, i) in tabdata" :key="i">
              <td style="padding-left: 10px">
                <p>{{ v.mchOrderNo}}</p>
              </td>
              <td>
                <p>{{ v.mchIdHj }}</p>
              </td>
              <td>
                <p>{{ OpenAnStatus[v.status] }}</p>
              </td>
              <td>
                <p>{{ receiveStatus[v.receiveAccountStatus] }}</p>
              </td>
              <td>
                <p>{{ v.bizMsg }}</p>
              </td>
              <td> 
                <p v-if="i == 0"><i class="lianjie" @click="toInfoUrl(v.mchId)">详情</i></p>
              </td>
              <td>
                <p v-if="i == 0"><i class="lianjie" @click="getSync(v.id)" v-auth="'ELECTRLED:MCHCOM:MCHLIST:CHANNEL/SYNC'">同步</i></p>
              </td>
            </tr>
          </table>
        </div>
        <!-- 注：仅当审核状态为审核通过,开户状态为未开户或开通失败的时候才能触发开户，否则置灰 -->
        <div class="status_desc">
          <span>开户状态</span>
          <span class="status" v-if="tabdata==null || tabdata.length<=0">未开户</span>
          <span class="status" v-else>{{ OpenAnStatus[tabForm.status] }}</span>
          <el-button
            @click="merchantCreateBtn()"
            v-auth="'ELECTRLED:MCHCOM:MCHLIST:CHANNEL/OPENAN'"
            :disabled="inGoodDisabled "
            :class="inGoodClass ? 'active' :'' "
            class="returnBt"
            type="primary"
            >开户</el-button
          >
      </div>
      </div>     
    </div>
  </div>
</template>
<script>
import { ledgerDetail ,merchantCreateQuery , merchantCreate} from "@/api/user/shopManager.js";
export default {
  data() {
    return {
      tabdata:{},
      mchId:"",
      OpenAnStatus:{//开户状态
        "P1000":"申请单已创建",
        "P2000":"待验证",
        "P3000":"银行审核中",
        "P4000":"电子签约中",
        "P5000":"开通成功",
        "P6000":"开通失败",
      },
      receiveStatus:{//收款账号认证状态
        "P1000":"待验证",
        "P2000":"验证中",
        "P3000":"验证成功",
        "P4000":"验证失败",
        "P5000":"验证锁定中",
      },
      tabForm:{},//取第一条数据
      inGoodClass:false,//开户按钮状态
      inGoodDisabled:true,//开户按钮是否能点击
      mchProfileApplyStatus:false,
    };
  },
  created() {
    this.mchId = this.$route.query.id;
    this.getDetail();  
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
          this.tabdata = res.resultData.mchProfileCreate;
          let mchProfileApply = res.resultData.mchProfileApply;
          if(mchProfileApply.length>0){
            if(res.resultData.mchProfileApply[0].status != null && res.resultData.mchProfileApply[0].status != '' && res.resultData.mchProfileApply[0].status == 'P4000'){
              this.mchProfileApplyStatus = true; //审核状态为审核通过
            }
          }else{
            this.mchProfileApplyStatus = false;
          } 

          if(this.mchProfileApplyStatus == true){//审核状态为审核通过
            if(this.tabdata.length>0){//开户状态
              this.tabForm = res.resultData.mchProfileCreate[0];
              if(this.tabForm.status != null && this.tabForm.status != '' &&  this.tabForm.status != 'P6000'){
                this.inGoodClass = true;
              }
              if(this.tabForm.status == 'P6000'){
                this.inGoodDisabled = false;
              }
            }else{
              this.inGoodDisabled = false;
            }
          }else{
            this.inGoodDisabled = true;
            this.inGoodClass = true;
          }
          
        }
      });
    },
    // 同步
    getSync(id) {
      merchantCreateQuery(id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("同步请求成功");
        }
        this.getDetail();
      });
    },
    //开户
    merchantCreateBtn() {
      if(this.tabForm.status != null && this.tabForm.status != '' &&  this.tabForm.status != 'P6000'){
          this.$message.error("开户状态为未进件或开通失败时，才能触发开户");
          return false;
      }
      this.$confirm("是否进行开户", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          merchantCreate(this.$route.query.id).then((res) => {
            if (res.resultStatus) {
              this.$message.success("开户成功");
            }
            this.getDetail();
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    //
    toInfoUrl(infoId) {
      this.$router.push({
        name: 'ledger_detail_dzzb_info',
        query: {
          infoId:infoId,
          id:this.mchId,
        },
      });
    },   
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: var(--title);
}
.back:hover {
  background: var(--themeColor);
  border-color: var(--themeColor);
  color: #fff;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  text-align: left;
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
.status_desc {
  height: 32px;
  margin: 0 0px 20px 0px;
  padding: 10px 30px 10px 35px;
  background-color: #f4f5f6;
  font-size: 16px;
  line-height: 32px;
  color: var(--label);
}

.status_desc .status {
  margin-left: 56px;
  color: var(--title);
}

.status_desc .overBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}

.status_desc .overBt.active {
  background: var(--themeColor);
  border-color: var(--themeColor);
}

.status_desc .returnBt {
  float: right;
  width: 60px;
  height: 32px;
  padding: 0;
  margin-left: 10px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
  font-size: 12px;
  color: var(--title);
}

.status_desc .returnBt.active {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
}
</style>
